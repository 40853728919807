/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpPostHandOffToAcitvateRegisterStates($stateProvider) {
  'ngInject';
  $stateProvider.state('post-handoff-to-activate-register', {
    parent: 'public',
    url: '/post-handoff-to-activate-register?limitedAccessToken',
    controller: 'AcpPostHandOffToActivateRegisterComponentCtrl',
    controllerAs: 'vm',
    params: {
      limitedAccessToken: null
    },
    // Add resolve data as attributes: <acp-spend-money data="$resolve.fetchedData" ... />
    template: '',
    resolve: {
      module($ocLazyLoad) {
        'ngInject';
        return import(
          /* webpackChunkName: "acp.section.post-handoff-to-activate-register" */ './index'
        ).then($ocLazyLoad.loadModule);
      }
    }
  });
}
